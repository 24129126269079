@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Jaldi&family=Poppins:wght@600&family=Roboto:wght@300;400;700;900&display=swap";
:root {
  --bs-body-font-family: "Roboto", var(--bs-font-sans-serif);
  --bs-heading-font-family: "Inter", var(--bs-body-font-family);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

h1, h2, h3, h4 {
  font-family: var(--bs-heading-font-family);
}

.heading {
  color: #fff;
  text-align: center;
  margin-bottom: 22px;
  padding: 50px;
  font-size: 50px;
  font-weight: 600;
  line-height: 78px;
}

.navbar {
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.logo {
  color: #f3f3f7;
}

nav {
  justify-content: space-between;
  align-items: center;
  width: 84%;
  margin: auto;
  padding: 20px 0;
  display: flex;
}

nav ul li {
  vertical-align: middle;
  text-align: center;
  margin: 0 18px;
  list-style: none;
  display: inline-block;
}

nav ul a {
  color: #f3f3f7;
  cursor: pointer;
  font-weight: lighter;
  text-decoration: none;
}

nav ul button {
  color: #f3f3f7;
  font-family: var(--bs-heading-font-family);
  text-align: center;
  background: linear-gradient(#0276d9 0%, #252063 100%);
  border: 0;
  border-radius: 20px;
  width: 145px;
  margin-bottom: 7px;
  padding: 16px 21px 16px 20px;
  font-size: 15px;
  font-weight: 800;
  line-height: 18px;
}

.bg-light {
  justify-content: center;
  background-color: #000000b3 !important;
}

.navbar .nav-link {
  text-align: left;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.navbar-collapse {
  flex-grow: 0;
}

.navbar .nav-link {
  text-align: center;
  margin-top: 8px;
}

.nav-item.dropdown {
  position: relative;
}

.nav-item.dropdown .dropdown-menu {
  z-index: 10000;
  background-color: #000000e6;
  border: none;
  border-radius: 5px;
  min-width: 200px;
  padding: 10px 0;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 4px 6px #0003;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu .dropdown-item {
  color: #f3f3f7;
  padding: 10px 15px;
  font-size: 14px;
}

.dropdown-menu .dropdown-item:hover {
  color: #fff;
  background-color: #0276d9;
}

@media (width <= 991.98px) {
  .nav-item.dropdown .dropdown-menu {
    background-color: #000000f2;
    border-radius: 0;
    position: static;
  }
}

#hero-section {
  background: linear-gradient(150.19deg, #000 -.02%, #382eb3 24.99%, #1f1a56 61.98%, #191549 100.02%);
  border: 0;
  padding: 0;
}

.hero-section {
  background-color: #12102182;
  background-image: url("dd5ad187.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  border-bottom-right-radius: 130px;
  border-bottom-left-radius: 130px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 20px;
  display: flex;
}

.A1 {
  cursor: default;
  width: 390px;
  height: 131px;
}

.B1 {
  color: #fff;
  width: 571px;
  margin-top: -100px;
  margin-bottom: 30px;
  font-size: 45px;
  font-weight: normal;
}

.B1 #solidez {
  width: 287px;
  height: 66px;
  font-family: Poppins, sans-serif;
  font-size: 45px;
  font-weight: 600;
  line-height: 0;
}

.B1 #new_line {
  font-size: 45px;
  font-weight: 300;
  font-family: var(--bs-body-font-family);
  margin-left: 65px;
}

#hero-section h2 {
  color: #87b2d9;
  font-family: var(--bs-body-font-family);
  margin-bottom: 44px;
  font-size: 40px;
  line-height: 51px;
}

.graph {
  opacity: .9;
  width: 470px;
}

.wa-button {
  color: #f3f3f7;
  background-color: #2db526;
  border-radius: 6px;
  justify-content: space-evenly;
  align-items: center;
  width: 342px;
  height: 67px;
  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.wa-button img {
  text-align: center;
  height: 36px;
}

.cards {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 0 auto;
  display: flex;
}

.card {
  background-color: #fff;
  border-radius: 20px;
  width: 301px;
  padding-top: 10px;
  position: relative;
  overflow: hidden;
}

.card-image {
  z-index: 1;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
}

.card-image h3 {
  font-family: var(--bs-body-font-family);
  color: #12141d;
  width: 20vw;
  font-size: 20px;
  font-weight: bold;
  line-height: 29px;
}

.card-image img {
  width: auto;
  height: 10vh;
}

.card-design img {
  width: 300px;
  height: 300px;
  position: absolute;
  top: -76px;
  right: -38px;
}

.card-text {
  color: #12141d;
  opacity: .7;
  margin-top: 15px;
  padding: 0 24px 24px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.discount-section {
  display: none;
}

#como-funciona {
  background: #0e0f1e;
}

#como-funciona .swiper {
  max-width: 1360px;
}

#como-funciona .swiper-pagination {
  font-family: var(--bs-heading-font-family);
  color: #e8e8e8;
  position: initial;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  margin-left: 50px;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  display: flex;
}

#como-funciona .swiper-pagination .swiper-pagination-bullet {
  cursor: pointer;
  opacity: initial;
  height: initial;
  background: none;
  width: auto;
  margin: 0 2%;
  padding: 15px 34px;
  font-size: 14px;
}

#como-funciona .swiper-pagination .swiper-pagination-bullet-active {
  color: #c7c3ff;
  cursor: default;
  background: linear-gradient(107.73deg, #5c53b96e .62%, #5c53b915 83.79%);
  border-radius: 8px;
}

#como-funciona .img-overlay {
  float: right;
  margin-bottom: 29px;
  margin-left: 48px;
  position: relative;
}

#como-funciona .img-overlay:before {
  content: "";
  background-image: linear-gradient(#00164159, #00164159);
  background-size: cover;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#como-funciona .img-overlay img {
  border-radius: 8px;
}

.swiper-slide {
  padding: 32px 60px 90px;
}

.swiper-slide img {
  float: right;
}

.swiper-slide li {
  color: #fff;
}

.swiper-slide h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

#como-funciona .action-link a {
  color: #1499b6;
  font-size: 18px;
}

.whatsapp-logo img {
  width: 84px;
  height: 85px;
  position: absolute;
  bottom: 130px;
  left: 1286px;
}

.structure-text p {
  color: #fff;
}

.one .text-box {
  position: absolute;
  top: 114px;
  right: 486px;
}

.two .text-box {
  margin-top: 40px;
}

.three .image img {
  width: 450px;
  height: 299px;
}

.three .text-box {
  margin-top: 40px;
  margin-left: 30px;
}

.four .text-box {
  width: 419px;
  height: 206px;
  margin-top: 131px;
  margin-left: 6px;
}

.four .image img {
  width: 365px;
  height: 359px;
}

.section-wrapper {
  background-image: linear-gradient(#001641bf, #001641bf);
  background-size: cover;
  width: 100%;
  height: 100%;
}

.accordion {
  background: #fff;
  border-radius: 20px;
  max-width: 878px;
  margin: auto;
  padding: 50px 90px 40px;
}

#faq {
  font-family: var(--bs-heading-font-family);
  background: url("3ddb55d4.jpeg") top / cover;
}

#faq .tabs {
  display: none;
}

.accordion .accordion-item {
  background: none;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.accordion button {
  text-align: left;
  color: #170f49;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  margin: auto;
  padding: 1.2em 0;
  font-size: 1.2rem;
  font-weight: 400;
  display: block;
  position: relative;
}

.accordion button:hover, .accordion button:focus {
  cursor: pointer;
  color: #4a3aff;
}

.accordion button:hover:after, .accordion button:focus:after {
  cursor: pointer;
  color: #4a3aff;
  border: 1px solid #4a3aff;
}

.accordion button .accordion-title {
  padding-right: 1em;
  font-weight: 600;
  display: block;
}

.accordion button .icon {
  vertical-align: middle;
  border-radius: 22px;
  width: 26px;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.accordion button .icon:before {
  content: "+";
  margin-top: -22px;
  font-size: 30px;
  display: block;
  position: absolute;
  top: 50%;
  left: 3px;
}

.accordion button .icon:after {
  content: "";
  background: currentColor;
  width: 2px;
  height: 16px;
  display: none;
  position: absolute;
  top: 3px;
  left: 10px;
}

.accordion button[aria-expanded="true"] {
  color: #4a3aff;
}

.accordion button[aria-expanded="true"] .icon:before {
  content: "-";
}

.accordion button[aria-expanded="true"] .icon:after {
  width: 0;
}

.accordion button[aria-expanded="true"] + .accordion-content {
  opacity: 1;
  will-change: opacity, max-height;
  max-height: 900px;
  transition: all .2s linear;
}

.accordion .accordion-content {
  opacity: 0;
  will-change: opacity, max-height;
  max-height: 0;
  transition: opacity .2s linear, max-height .2s linear;
  overflow: hidden;
}

.accordion .accordion-content p {
  color: #6f6c90;
  margin: 0 0 2em;
  font-size: 1rem;
  font-weight: 300;
}

.accordion .accordion-item:last-of-type {
  border: none;
}

.accordion .card:first-child {
  padding-top: 50px;
}

.accordion .card:last-child {
  padding-bottom: 50px;
}

.accordion-icon[aria-expanded="false"] h5 {
  color: #000;
}

.accordion-icon[aria-expanded="true"] h5 {
  color: #4a3aff !important;
}

#faq .function-button ul {
  justify-content: center;
  padding-left: 0;
  position: static;
}

#footer a {
  font-weight: 300;
}

#footer ul {
  padding-left: 0;
}

.footer-section {
  color: #fff;
  font-family: var(--bs-heading-font-family);
  background: #101011;
  width: 100%;
  font-size: .9em;
}

.footer-column {
  justify-content: center;
  align-items: flex-start;
  gap: 100px;
  padding-top: 78px;
  padding-bottom: 23px;
  display: flex;
  position: relative;
}

.footer-section i {
  color: #fff;
}

.footer-section li {
  margin-top: 20px;
  font-family: Inter, sans-serif;
  list-style: none;
}

.footer-section .column-heading {
  color: #fff;
  border-bottom: 1px solid #6f7074;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.column-1 {
  flex-direction: column;
  justify-content: space-between;
  gap: 145px;
  display: flex;
}

.column-1 .social-icons i {
  color: #fff;
  margin-left: 10px;
  font-size: 25px;
}

.column-3 .icon-text {
  color: #fafafa;
  margin-top: 20px;
}

.column-3 .icon-text i {
  margin-right: 15px;
}

.column-3 .icon-text span {
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
}

.copyright {
  text-align: center;
  border-top: 1px solid #6f7074;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  display: flex;
}

.copyright p {
  color: #6f7074;
  padding-top: 50px;
}

#passoapasso {
  color: #fff;
  background: linear-gradient(150.19deg, #310070 -.02%, #191549 24.99%, #000 53.65%, #1a1563 76.05%, #241e7c 100.02%);
  padding: 40px 20px;
}

#passoapasso .upper-section {
  margin-bottom: 13px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ;
}

#passoapasso > div {
  max-width: 1400px;
}

#passoapasso h3 {
  color: #9188fe;
  margin-bottom: 15px;
  font-weight: 700;
}

.background-container {
  background-size: cover;
  width: 100%;
  max-width: 1252px;
  max-height: 2155px;
  margin: 0 auto;
  padding-top: 1px;
  position: relative;
}

.background-container > img {
  width: 100%;
}

#passoapasso .right {
  left: 47%;
}

#passoapasso .left {
  text-align: right;
  left: 16%;
}

.text-box p {
  font-size: 1.3vw;
}

.text-box {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  width: 39%;
  margin-top: 17%;
  display: flex;
  position: absolute;
  top: 0;
}

.text-box p.short {
  display: none;
}

#contrato {
  margin-top: 50%;
}

#implantacao {
  margin-top: 92%;
  margin-left: 10%;
}

#cadastro-de-acesso {
  margin-top: 133%;
  margin-left: 10%;
}

#faq .container {
  max-width: 1360px;
  margin: 0 auto;
}

.navbar-toggler {
  border: 0;
}

.navbar-toggler span {
  color: gray;
  font-size: 12px;
}

.navbar-toggler:focus, .navbar-toggler:active {
  box-shadow: none;
  border-color: #0000;
  outline: none;
}

.function-pc {
  display: block;
}

#whatsapp-icon {
  z-index: 2147483647;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

#cards-section .card-image > span {
  border: 1px solid #dde1e0;
  border-radius: 44px;
}

#cards-section .card-image img {
  box-sizing: content-box;
  width: 44px;
  height: 44px;
  padding: 14px;
}

@media screen and (width <= 992px) {
  .bg-light {
    justify-content: space-between;
    padding: 0 5%;
  }

  .navbar-nav .nav-item:last-child {
    display: none;
  }
}

@media screen and (width <= 900px) {
  .footer-column {
    flex-wrap: wrap;
  }

  .A1 {
    width: 300px;
    height: 131px;
  }

  .B1 {
    width: 571px;
    height: 131px;
    margin-top: -100px;
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 300;
  }

  .B1 #solidez {
    width: 287px;
    height: 66px;
    font-family: Poppins, sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 0;
  }

  .B1 #new_line {
    margin-left: 65px;
    font-size: 40px;
    font-weight: 300;
  }

  .graph img {
    opacity: .7;
    width: 400px;
    height: 400px;
  }
}

@media screen and (width <= 780px) {
  .accordion {
    padding: 50px 35px 40px;
  }

  .A1 {
    width: 259px;
    height: 131px;
  }

  .B1 {
    width: 571px;
    height: 131px;
    margin-top: -100px;
    margin-bottom: -15px;
  }

  .B1 #solidez {
    width: 267px;
    height: 55px;
    font-size: 36px;
    font-weight: 600;
    line-height: 0;
  }

  .B1 #new_line {
    margin-left: 65px;
    font-size: 36px;
    font-weight: 300;
  }

  .graph img {
    opacity: .7;
    width: 360px;
    height: 360px;
  }
}

@media only screen and (width <= 730px) {
  .footer-section li, .column-3 .icon-text {
    margin-top: 11px;
  }

  .column-1 .social-icons i {
    font-size: 18px;
  }
}

@media screen and (width <= 700px) {
  .A1 {
    width: 210px;
    height: 131px;
  }

  .B1 {
    width: 571px;
    height: 131px;
    margin-top: -82px;
    margin-bottom: -35px;
    font-size: 31px;
    font-weight: 300;
  }

  .B1 #solidez {
    width: 250px;
    height: 51px;
    font-size: 31px;
    font-weight: 600;
    line-height: 0;
  }

  .B1 #new_line {
    margin-left: 65px;
    font-size: 31px;
    font-weight: 300;
  }

  .graph img {
    opacity: .7;
    width: 320px;
    height: 320px;
  }

  .function-button ul {
    margin-top: -7px;
    margin-bottom: 32px;
  }

  .heading {
    font-size: 7vw;
    line-height: 9vw;
  }
}

@media only screen and (width <= 660px) {
  .tab-contents img {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (width <= 590px) {
  .hero-section {
    background-size: 146%;
    border-bottom-right-radius: 75px;
    border-bottom-left-radius: 75px;
    height: 310px;
  }

  .copyright p {
    padding-top: 9px;
    font-size: 10px;
  }
}

@media only screen and (width <= 576px) {
  .hero-section {
    height: 280px;
  }

  .function-button li {
    margin-left: 20px;
  }

  .tab-contents img {
    border-radius: 0;
    width: 70vw;
    height: 70vh;
  }

  #faq .container {
    width: 100%;
    max-width: 100%;
  }

  .footer-section {
    height: 910px;
    padding: 0 26px;
  }

  .footer-column {
    text-align: center;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 50px;
    padding-top: 100px;
  }

  #cards-section h3 {
    width: 100%;
    font-size: 18px;
  }

  #cards-section .card-text {
    font-size: 16px;
    line-height: 19px;
  }

  #cards-section .card-image {
    flex-flow: column wrap;
    place-content: flex-start;
    align-items: flex-start;
    margin-left: 26px;
  }

  #cards-section .card-image br {
    display: none;
  }

  .card-text {
    margin-top: 0 !important;
  }

  .card-image {
    margin-top: 10px !important;
  }

  .upper-section .heading {
    padding-bottom: 5%;
    padding-left: 2%;
    padding-right: 2%;
    font-size: 2.2rem;
  }

  .function-pc {
    display: none;
  }

  #faq h1 {
    font-size: 1.5rem !important;
  }
}

@media only screen and (width <= 500px) {
  .hero-section {
    height: 270px;
  }

  .function-button li {
    margin-left: 20px;
  }
}

@media only screen and (width <= 432px) {
  .hero-section {
    height: 270px;
  }

  .function-button li {
    color: #e8e8e8;
    margin-left: 15px;
    margin-right: 6px;
    font-size: 11px;
    line-height: 18px;
  }

  .tab-contents img {
    border-radius: 0;
    width: 60vw;
    height: 50vh;
  }
}

@media only screen and (width <= 480px) {
  .hero-section {
    height: 227px;
  }
}

@media only screen and (width <= 400px) {
  .hero-section {
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 0 45px 36px;
  }

  .section-of-function .heading {
    padding: 25px 50px;
  }

  .function-button li {
    color: #e8e8e8;
    margin-left: 13px;
    margin-right: 6px;
    font-size: 10px;
    line-height: 18px;
  }

  .tab-contents img {
    border-radius: 0;
    width: 70vw;
    height: 50vh;
  }

  .discount-section {
    background-color: #000;
    flex-direction: column;
    align-items: center;
    width: 375px;
    max-width: 100vw;
    height: 568px;
    padding: 40px 20px;
    display: flex;
  }

  .upper-discount {
    flex-direction: column;
    align-items: center;
    gap: 3px;
    display: flex;
  }

  .upper-discount .upper-discount-heading h2 {
    font-family: var(--bs-heading-font-family);
    letter-spacing: -.270833px;
    text-align: center;
    color: #fff;
    width: 280px;
    height: 44px;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
  }

  .upper-discount .upper-discount-text {
    font-family: var(--bs-heading-font-family);
    letter-spacing: 0;
    text-align: center;
    color: #fff;
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
  }

  .upper-discount .upper-discount-link a {
    font-family: var(--bs-heading-font-family);
    letter-spacing: 0;
    text-align: center;
    color: #1499b6;
    width: 62px;
    height: 15px;
    font-size: 12px;
    font-weight: 500;
    line-height: 7px;
  }

  .lower-discount {
    justify-content: center;
    align-items: center;
    margin-top: -23px;
    display: flex;
  }

  .lower-discount img {
    width: 340px;
    height: 260px;
  }

  .footer-section {
    height: 817px;
  }

  .footer-column-dash li {
    margin-top: 4px;
  }

  #cards-section .cards-pc .card {
    width: 157px;
    height: 191px;
  }
}

@media screen and (width <= 760px) {
  #como-funciona .swiper-pagination .swiper-pagination-bullet {
    padding: 3% 4%;
    font-size: 2.5vw;
  }

  #como-funciona .swiper-pagination {
    margin-left: 0;
  }

  #como-funciona h2 {
    text-align: center;
  }

  #como-funciona .img-overlay {
    width: 100%;
    min-width: 274px;
  }

  #como-funciona .img-overlay img {
    width: 100%;
  }

  #como-funciona .swiper-slide {
    padding: 32px 7% 90px;
  }

  #como-funciona ul {
    padding-left: 6%;
  }

  #passoapasso {
    padding: 40px 5px;
  }

  #passoapasso .left {
    left: 4%;
  }

  #passoapasso .text-box {
    width: 48%;
    margin-top: 5%;
  }

  #passoapasso .text-box p.short {
    display: block;
  }

  #passoapasso .text-box p.long {
    display: none;
  }

  #passoapasso #contrato {
    margin-top: 48%;
  }

  #passoapasso #implantacao {
    margin-top: 88%;
    left: 42%;
  }

  #passoapasso #cadastro-de-acesso {
    margin-top: 131%;
  }

  #passoapasso h3 {
    font-size: 4.5vw;
  }

  #passoapasso p {
    font-size: 2.7vw;
  }

  #passoapasso .description p {
    font-size: 1rem;
  }
}

@media screen and (width <= 1023px) {
  #como-funciona .img-overlay {
    float: none;
    width: 53%;
    margin: 0 auto 41px;
  }

  #como-funciona .img-overlay img {
    float: none;
    width: 100%;
  }
}

@media screen and (width >= 1400px) {
  #passoapasso h3 {
    font-size: 32px;
  }

  #passoapasso p {
    font-size: 18px;
  }
}

@media only screen and (width <= 840px) {
  .graph {
    display: none;
  }

  .hero-section {
    border-bottom-right-radius: 8vw;
    border-bottom-left-radius: 8vw;
  }

  #hero-section h2 {
    text-align: right;
    float: right;
    margin-top: -7vw;
    font-size: 5vw;
    line-height: 6vw;
  }

  .A1 {
    width: 95%;
    height: 35vw;
  }

  .B1 {
    width: auto;
    height: auto;
    margin: 3vw 0;
    font-size: 5vw;
  }

  .wa-button {
    float: left;
    width: 48%;
    height: 26%;
    margin-top: 2vw;
    font-size: 3.2vw;
  }

  .wa-button img {
    height: 53%;
  }

  .column-1 #logo {
    width: 130px;
    height: 54px;
  }

  .footer-section .column-heading {
    font-size: 13px;
  }

  .footer-section li {
    margin-top: 14px;
    font-size: 11px;
  }

  .column-3 .icon-text {
    margin-top: 14px;
  }

  .column-1 .social-icons i {
    font-size: 22px;
  }
}
/*# sourceMappingURL=f06fc7cc.css.map */
