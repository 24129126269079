@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Jaldi&family=Poppins:wght@600&family=Roboto:wght@300;400;700;900&display=swap');

:root {
    --bs-body-font-family:
    "Roboto", var(--bs-font-sans-serif);
    --bs-heading-font-family:
    'Inter', var(--bs-body-font-family);
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a {
    color: #FFF;
    text-decoration: none;
}
body {
    overflow-x: hidden;
    max-width: 100vw;
}

h1, h2, h3, h4 {
    font-family: var(--bs-heading-font-family);
}

.heading {
    font-weight: 600;
    font-size: 50px;
    line-height: 78px;
    /* line-height: 9vw; */
    color: #FFFFFF;
    text-align: center;
    padding: 50px;
    margin-bottom: 22px;
}

/* ---------------navbar section--------------- */
.navbar {
    width: 100%;
    background-color: rgb(0, 0, 0);
    background-repeat: no-repeat;
    background-position: center;
    max-width: 100vw;
    position: fixed;
    top: 0;
    left: 0;

}

.logo {
    color: #f3f3f7;


}

nav {
    display: flex;
    width: 84%;
    margin: auto;
    padding: 20px 0;
    align-items: center;
    justify-content: space-between;
}

nav ul li {
    display: inline-block;
    list-style: none;
    margin: 0 18px;
    vertical-align: middle;
    text-align: center;
}

nav ul a {
    text-decoration: none;
    color: #f3f3f7;
    font-weight: lighter;

    cursor: pointer;


}

nav ul button {

    background: linear-gradient(180deg, #0276D9 0%, #252063 100%);

    color: #f3f3f7;
    width: 145px;
    /* height:35px ; */
    border-radius: 20px;
    border: 0;
    padding: 16px 21px 16px 20px;
    font-family: var(--bs-heading-font-family);
    font-size: 15px;
    font-weight: 800;
    line-height: 18px;
    text-align: center;
    margin-bottom: 7px;

}

.bg-light {
    background-color: rgba(0, 0, 0, 0.7) !important;
    justify-content: center;

}

.navbar .nav-link {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #FFFFFF;
}

.navbar-collapse {
    flex-grow: 0;
}

.navbar .nav-link {
    text-align: center;
    margin-top: 8px;
    /* vertical-align: middle; */
    /* display: inline; */
}

/* -----------------------------dropdown----------------------- */
.nav-item.dropdown {
    position: relative;
}

.nav-item.dropdown .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    border: none;
    border-radius: 5px;
    padding: 10px 0;
    z-index: 10000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    min-width: 200px;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu .dropdown-item {
    color: #f3f3f7;
    padding: 10px 15px;
    font-size: 14px;
}

.dropdown-menu .dropdown-item:hover {
    background-color: #0276D9;
    color: #fff;
}

/* mobile*/
@media (max-width: 991.98px) {
    .nav-item.dropdown .dropdown-menu {
        position: static;
        background-color: rgba(0, 0, 0, 0.95);
        border-radius: 0;
    }
}

/*-------------------------------hero section--------------------*/

#hero-section {
    background: linear-gradient(150.19deg, #000000 -0.02%, #382EB3 24.99%, #1F1A56 61.98%, #191549 100.02%);

    padding: 0;
    border: 0;


}

.hero-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding-top: 48px;
    padding-bottom: 20px;
    background-image: url("img/handsome_guard.jpg");
    background-size: cover;
    background-color: rgba(18, 16, 33, 0.51);
    background-repeat: no-repeat;
    background-position: top center;
    /* border-radius: 0px, 0px, 150px, 150px; */
    border-bottom-left-radius: 130px;
    border-bottom-right-radius: 130px;
    border: 0;
}

.A1 {

    width: 390px;
    height: 131px;
    cursor: default;
}

.B1 {
    color: #FFFFFF;
    width: 571px;
    font-weight: normal;
    font-size: 45px;
    margin-top: -100px;
    margin-bottom: 30px;
}

.B1 #solidez {
    font-size: 45px;
    font-weight: 600;
    width: 287px;
    height: 66px;
    font-family: 'Poppins', sans-serif;
    line-height: 0;
}

.B1 #new_line {
    margin-left: 65px;
    font-weight: 300;
    font-size: 45px;
    font-family: var(--bs-body-font-family);
}

#hero-section h2 {
    color: #87B2D9;
    font-family: var(--bs-body-font-family);
    font-size: 40px;
    line-height: 51px;
    margin-bottom: 44px;

}

.graph {
    width: 470px;
    opacity: 0.9;
}

.wa-button {
    background-color: #2DB526;
    color: #f3f3f7;
    height: 67px;
    width: 342px;
    font-size: 24px;
    font-weight: 700;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
}

.wa-button img {
    height: 36px;
    text-align: center;
}

/*---------------------------------card section---------------------*/

.cards {
    display: flex;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    /* max-width: 100%; */
    margin: 0 auto;
}

.card {
    position: relative;
    background-color: #FFF;
    border-radius: 20px;
    padding-top: 10px;
    overflow: hidden;
    width: 301px;
}

.card-image {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    margin-left: 20px;
    /* max-width: 100%;
    padding-right: 30px;
    padding-left: 30px; */
    z-index: 1;
}

.card-image h3 {
    /* text-align: center; */
    /* margin-top: 20px; */
    font-family: var(--bs-body-font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    color: #12141D;
    width: 20vw;

}

.card-image img {
    width: auto;
    height: 10vh;
}

.card-design img {
    position: absolute;
    top: -76px;
    right: -38px;
    width: 300px;
    height: 300px;
}

.card-text {
    margin-top: 15px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #12141D;
    opacity: 0.7;
    padding: 0 24px 24px;


    /* font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left; */


}

/*---------------------------------discount section---------------------*/
.discount-section {
    display: none;
}


/* -----------------como funciona-------------*/
#como-funciona {
    background: #0E0F1E;
}
#como-funciona .swiper {
    max-width: 1360px;
}

#como-funciona .swiper-pagination {
    font-family: var(--bs-heading-font-family);
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #E8E8E8;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    margin-left: 50px;
    position: initial;
}

#como-funciona .swiper-pagination .swiper-pagination-bullet {
    margin: 0 2%;
    cursor: pointer;
    opacity: initial;
    background: none;
    width: auto;
    height: initial;
    padding: 15px 34px;
    font-size: 14px;
}
#como-funciona .swiper-pagination .swiper-pagination-bullet-active {
    background: linear-gradient(107.73deg, rgba(92, 83, 185, 0.43) 0.62%, rgba(92, 83, 185, 0.0817) 83.79%);
    color: #C7C3FF;
    border-radius: 8px;
    cursor: default;
}
#como-funciona .img-overlay {
    float: right;
    position: relative;
    margin-bottom: 29px;
    margin-left: 48px;
}
#como-funciona .img-overlay::before {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0,22,65,0.35), rgba(0,22,65,0.35));
    background-size: cover;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
}
#como-funciona .img-overlay img {
    border-radius: 8px;
}
.swiper-slide {
    padding: 32px 60px 90px;
}
.swiper-slide img{
    float: right;
}
.swiper-slide li {
    /* font-weight: 400; */
    /* font-size: 16px; */
    /* line-height: 23px; */
    color: #FFFFFF;
}
.swiper-slide h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #FFF;
    margin-bottom: 20px;
}
#como-funciona .action-link a {
    /* font-weight: 500; */
    font-size: 18px;
    /* line-height: 24px; */
    color: #1499B6;
}


.whatsapp-logo img {
    position: absolute;
    bottom: 130px;
    left: 1286px;
    width: 84px;
    height: 85px

}

/*-------------------structure section=-----------*/


.structure-text p {
    color: white;
}


.one .text-box {
    position: absolute;
    top: 114px;
    right: 486px;
}

.two .text-box {
    margin-top: 40px;
    /* margin-right: 30px; */
}

.three .image img {
    width: 450px;
    height: 299px;
}

.three .text-box {
    margin-top: 40px;
    margin-left: 30px;

}

.four .text-box {
    margin-left: 6px;
    margin-top: 131px;
    width: 419px;
    height: 206px;
}

.four .image img {
    width: 365px;
    height: 359px;
}


/*-----------------------accordion -----------------------*/

.section-wrapper {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0,22,65,0.75), rgba(0,22,65,0.75));
    background-size: cover;
    /* filter: blur(0px); */
}
.accordion {
    border-radius: 20px;
    padding: 50px 90px 40px;
    background: #FFF;
    max-width: 878px;
    margin: auto;
}

#faq {
    background: url("img/faq-bg.jpg?quality=60") top center;
    background-size: cover;
    font-family: var(--bs-heading-font-family);
}

#faq .tabs{
    display: none;
}

.accordion .accordion-item {
    border: none;
    border-bottom: 1px solid #e5e5e5;
    background: none;
}
.accordion .accordion-item button[aria-expanded=true] {
    /* border-bottom: 1px solid #D9DBE9; */
}
.accordion button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1.2em 0;
    color: #170F49;
    font-size: 1.2rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    margin: auto;
    /* vertical-align: middle; */
}
.accordion button:hover, .accordion button:focus {
    cursor: pointer;
    color: #4A3AFF;
}
.accordion button:hover::after, .accordion button:focus::after {
    cursor: pointer;
    color: #4A3AFF;
    border: 1px solid #4A3AFF;
}
.accordion button .accordion-title {
    padding-right: 1em;
    font-weight: 600;
    /* box-sizing: content-box; */
    display: block;
}
.accordion button .icon {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 26px;
    height: 100%;
    /* border: 1px solid; */
    border-radius: 22px;
    vertical-align: middle;
}
.accordion button .icon::before {
    display: block;
    position: absolute;
    content: "+";
    top: 50%;
    margin-top: -22px;
    left: 3px;
    /* width: 17px; */
    /* height: 15px; */
    /* background: currentColor; */
    font-size: 30px;
}
.accordion button .icon::after {
    display: none;
    position: absolute;
    content: "";
    top: 3px;
    left: 10px;
    width: 2px;
    height: 16px;
    background: currentColor;
}
.accordion button[aria-expanded=true] {
    color: #4A3AFF;
}
.accordion button[aria-expanded=true] .icon::before {
    content: "-";
}
.accordion button[aria-expanded=true] .icon::after {
    width: 0;
}
.accordion button[aria-expanded=true] + .accordion-content {
    opacity: 1;
    max-height: 900px;
    transition: all 200ms linear;
    will-change: opacity, max-height;
}
.accordion .accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
}
.accordion .accordion-content p {
    font-size: 1rem;
    font-weight: 300;
    margin: 0 0 2em;
    color: #6F6C90;
}
.accordion .accordion-item:last-of-type {
    border:none;
}





























.accordion .card:first-child {
    padding-top: 50px;
}

.accordion .card:last-child {
    padding-bottom: 50px;
}

.accordion-icon[aria-expanded="false"] h5 {
    color: black;
}

.accordion-icon[aria-expanded="true"] h5 {
    color: #4A3AFF !important;

}

#faq .function-button ul {

    position: static;
    justify-content: center;
    padding-left: 0;
}

/*--------------------footer section--------------------*/
#footer a{
    font-weight: 300;
}
#footer ul {
    padding-left: 0;
}

.footer-section {
    background: #101011;
    /* height: 480px; */
    width: 100%;
    color: white;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    font-family: var(--bs-heading-font-family);
    /* font-size: 14px; */
    font-size: 0.9em;
    /* font-size: 1.3vw; */
}

.footer-column {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 100px;
    position: relative;
    padding-top: 78px;
    padding-bottom: 23px;
}

.footer-section i {
    color: white;
}

.footer-section li {
    list-style: none;
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
    /* font-weight: 400; */
    /* font-size: 14px; */
    /* line-height: 30px; */
    /* color: #6F7074; */
}

/* .color-changed{
  color: #6F7074;

} */

.footer-section .column-heading {
    padding-bottom: 15px;
    border-bottom: 1px solid #6F7074;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
}

.column-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 145px;

}

.column-1 .social-icons i {
    margin-left: 10px;
    color: white;
    font-size: 25px;
}

.column-3 .icon-text {
    margin-top: 20px;
    color: #FAFAFA;


}

.column-3 .icon-text i {
    margin-right: 15px;
}

.column-3 .icon-text span {
    /* font-family: 'Inter', sans-serif; */
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    /* color: #6F7074; */
}

.copyright {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    /* align-items: center; */
    border-top: 1px solid #6F7074;
    width: 80%;
    /* text-align: center; */
    margin: 0 auto;
    /* padding: 0 10%; */
    text-align: center;
}

.copyright p {
    /* text-align: center; */
    padding-top: 50px;
    /* width: 1110px; */
    /* font-family: 'Inter', sans-serif; */
    /* font-weight: 400; */
    /* font-size: 14px; */
    /* line-height: 30px; */
    color: #6F7074;
}


/* PASSO A PASSO */
#passoapasso {
    padding: 40px 20px;
    background: linear-gradient(150.19deg, #310070 -0.02%, #191549 24.99%, #000000 53.65%, #1A1563 76.05%, #241E7C 100.02%);
    color: #FFF;
}
#passoapasso .upper-section {
    margin-bottom: 13px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:
}
#passoapasso > div {
    max-width: 1400px;
}
#passoapasso h3{
    /* font-size: 26px; */
    font-weight: 700;
    color: #9188FE;
    margin-bottom: 15px;
    /* font-size: 2.1vw; */
}

.background-container {
    width: 100%;
    max-width: 1252px;
    max-height: 2155px;
    background-size: cover;
    padding-top: 1px;
    margin: 0 auto;
    position: relative;
}
.background-container > img {
    width: 100%;
}

#passoapasso .right {
    /* margin-left: 6%; */
    left: 47%;
}
#passoapasso .left {
    left: 16%;
    text-align: right;
}

.text-box p {
    /* font-size: 16px; */
    font-size: 1.3vw;
}

.text-box {
    /* width: 468px; */
    width: 39%;
    margin-top: 17%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFF;
    position: absolute;
    top: 0;
    /* font-size: 2vw; */
}
.text-box p.short {
    display: none;
}

#contrato {
    margin-top: 50%;
}
#implantacao {
    margin-top: 92%;
    margin-left: 10%;
}
#cadastro-de-acesso {
    margin-top: 133%;
    margin-left: 10%;
}
/*@media screen and (max-width: 1216px) {
    .background-container {
        width: 600px;
        padding-top: 1px;
        margin: 0 auto;
        box-sizing: border-box;
    }
    #passoapasso .right {
    }
    #passoapasso .left {
    }
    .implantacao {
    }
    .cadastro-de-acesso {
    }
}*/
















#faq .container {
    margin: 0 auto;
    max-width: 1360px;
}

.navbar-toggler {
    border: 0;
}

.navbar-toggler span {
    font-size: 12px;
    color: grey;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: none; /* Remove the default focus outline */
    box-shadow: none; /* Remove any box shadow on active state */
    border-color: transparent; /* Set the border color to transparent */
}

.function-pc {
    display: block;
}

#whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999999999999;
}

#cards-section .card-image > span {
    border: solid #DDE1E0 1px;
    border-radius: 44px;
}
#cards-section .card-image img {
    width: 44px;
    height: 44px;
    padding: 14px;
    box-sizing: content-box;
}

/* -----------------media quries-------------*/


@media screen and (max-width: 992px) {
    .bg-light {
        justify-content: space-between;
        padding: 0 5%;
    }

    .navbar-nav .nav-item:last-child {
        display: none;
    }

    #cards-section .card-image img {
        /* width: 34px; */
        /* height: 34px; */
    }

}

@media screen and (max-width: 900px) {

    .footer-column {
        flex-wrap: wrap;
    }

    /*----------- hero section ---------*/
    .A1 {

width: 300px;

height: 131px;

}

    .B1 {
        width: 571px;
        Height: 131px;
        font-weight: 300;
        font-size: 40px;
        margin-top: -100px;
        margin-bottom: 20px;
    }

    .B1 #solidez {
        font-size: 40px;
        font-weight: 600;
        width: 287px;
        height: 66px;
        font-family: 'Poppins', sans-serif;
        line-height: 0;

    }

    .B1 #new_line {
        margin-left: 65px;
        font-weight: 300;
        font-size: 40px;
    }

    .graph img {
        width: 400px;
        height: 400px;

        opacity: 0.7;

    }

    .card-design img {
        /* width: 120px; */
        /* height: 120px; */
    }
}

@media screen and (max-width: 780px) {
    .accordion {
        padding: 50px 35px 40px;
    }
    /*----------- hero section ---------*/
    .A1 {

        width: 259px;
        height: 131px;

    }

    .B1 {
        width: 571px;
        Height: 131px;
        /* font-weight: 300; */
        /* font-size: 36px; */
        margin-top: -100px;
        margin-bottom: -15px;
    }

    .B1 #solidez {
        font-size: 36px;
        font-weight: 600;
        width: 267px;
        height: 55px;
        line-height: 0;

    }

    .B1 #new_line {
        margin-left: 65px;
        font-weight: 300;
        font-size: 36px;
    }

    .graph img {
        width: 360px;
        height: 360px;
        opacity: 0.7;

    }

    /* ----------------function section----------- */
}

@media only screen and (max-width: 730px) {

    /* -----------footer section---------- */
    .footer-section li {

        margin-top: 11px;
    }

    .column-3 .icon-text {
        margin-top: 11px;
    }


    .column-1 .social-icons i {
        font-size: 18px;
    }

}

@media screen and (max-width: 700px) {
    /*----------- hero section ---------*/
    .A1 {

        width: 210px;
        height: 131px;

    }

    .B1 {

        width: 571px;
        Height: 131px;
        font-weight: 300;
        font-size: 31px;
        margin-top: -82px;
        margin-bottom: -35px;

    }

    .B1 #solidez {
        font-size: 31px;
        font-weight: 600;
        width: 250px;

        height: 51px;
        line-height: 0;
    }

    .B1 #new_line {
        margin-left: 65px;
        font-weight: 300;
        font-size: 31px;
    }

    .graph img {
        width: 320px;
        height: 320px;
        opacity: 0.7;

    }

    /* -----------function section---------- */
    .function-button ul {
        margin-top: -7px;
        margin-bottom: 32px;
    }

    .heading {
        /* font-size: 30px; */
        font-size: 7vw;
        line-height: 9vw;
    }
}

@media only screen and (max-width: 660px) {
    /*----------- function section ---------*/
    .tab-contents img {
        width: 200px;
        height: 200px;
    }

}

@media only screen and (max-width: 590px) {
    /*----------- hero section ---------*/
    .hero-section {
        /* background-image: url("img/Frame 129324.png"); */
        height: 310px;
        background-size: 146%;
        border-bottom-left-radius: 75px;
        border-bottom-right-radius: 75px;
    }

    .copyright p {
        padding-top: 9px;
        /* padding-bottom: 5px; */
        font-size: 10px;
    }
}

@media only screen and (max-width: 576px) {

    /*----------- function section ---------*/
    .hero-section {
        height: 280px;
        /* border-radius: 500px; */
    }

    .function-button li {
        margin-left: 20px;
    }


    .tab-contents img {
        width: 70vw;
        height: 70vh;
        border-radius: 0;

    }

    #faq .container {
        max-width: 100%;
        width: 100%;
    }

    /*
    .tab-contents{
      display: none;

      margin-right:-80px;
    }
    .tab-contents img{
      width: 30vw;
      height:30vh;
    }
    .tab-contents.active-tab{
      display: block;
      width: 35vw;

    } */
    /*----------- footer section ---------*/
    .footer-section {
        height: 910px;
        padding: 0 26px;
    }

    .footer-column {
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 50px;
        padding-top: 100px;
        text-align: center;
    }

    #cards-section h3 {
        font-size: 18px;
        /* font-weight: 700; */
        /* line-height: 8.83px; */
        width: 100%;
    }

    #cards-section .card-text {
        font-size: 16px;
        line-height: 19px;
    }

    #cards-section .card-image {
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 26px;
    }

    #cards-section .card-image br {
        display: none;
    }

    .card-text {
        margin-top: 0 !important;
    }

    .card-image {
        margin-top: 10px !important;
    }

    .upper-section .heading {
        padding-left: 2%;
        padding-right: 2%;
        padding-bottom: 5%;
        font-size: 2.2rem;
    }


    .function-pc {
        display: none;
    }

    #faq h1 {
        font-size: 1.5rem !important;
    }
}

@media only screen and (max-width: 500px) {
    .hero-section {
        height: 270px;
    }

    .function-button li {
        margin-left: 20px;
    }


}

@media only screen and (max-width: 432px) {
    .hero-section {
        height: 270px;
    }

    /*----------- function section ---------*/
    .function-button li {
        margin-left: 15px;
        margin-right: 6px;
        font-size: 11px;
        line-height: 18px;
        color: #E8E8E8;
    }

    .tab-contents img {
        width: 60vw;
        height: 50vh;
        border-radius: 0;

    }

}


@media only screen and (max-width: 480px) {
    /*----------- hero section ---------*/
    .hero-section {
        height: 227px;
    }

}


@media only screen and (max-width: 400px) {
    /* ------------hero section--------- */
    .hero-section {


        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 0 0 45px 36px;


    }

    /* .text-2 .p{
      width: 70vw;
      height: 40vh;
      font-size: 12px;
    } */
    /* ------------function section--------- */
    .section-of-function .heading {
        padding: 25px 50px;
    }

    .function-button li {
        margin-left: 13px;
        margin-right: 6px;
        font-size: 10px;
        line-height: 18px;
        /*letter-spacing: 1pt;*/
        color: #E8E8E8;
    }

    .tab-contents img {
        width: 70vw;
        height: 50vh;
        border-radius: 0;

    }

    /* ------------dicount section--------- */
    .discount-section {
        background-color: #000000;
        width: 375px;
        height: 568px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100vw;
        padding: 40px 20px;

    }

    .upper-discount {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;
    }

    .upper-discount .upper-discount-heading h2 {
        width: 280px;
        height: 44px;
        font-family: var(--bs-heading-font-family);
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.2708333432674408px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 15px;


    }

    .upper-discount .upper-discount-text {
        font-family: var(--bs-heading-font-family);
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 15px;


    }

    .upper-discount .upper-discount-link a {
        width: 62px;
        height: 15px;
        font-family: var(--bs-heading-font-family);
        font-size: 12px;
        font-weight: 500;
        line-height: 7px;
        letter-spacing: 0;
        text-align: center;
        color: #1499B6;


    }

    .lower-discount {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -23px;
    }

    .lower-discount img {
        width: 340px;
        height: 260px;
    }

    /* ------------------footer section------------- */
    .footer-section {
        height: 817px;
    }

    .footer-column-dash li {
        margin-top: 4px;

    }


}


@media only screen and (max-width: 400px) {

    #cards-section .cards-pc .card {
        width: 157px;
        height: 191px;
    }

    /* #cards-section {
      padding-left: 80px !important;
      padding-right: 80px !important;

    } */
}





@media screen and (max-width: 760px) {
    #como-funciona .swiper-pagination .swiper-pagination-bullet {
        font-size: 2.5vw;
        padding: 3% 4%;
    }
    #como-funciona .swiper-pagination {
        margin-left: 0;
    }
    #como-funciona h2 {
        text-align: center;
    }
    #como-funciona .img-overlay {
        width: 100%;
        min-width: 274px;
    }
    #como-funciona .img-overlay img {
        width: 100%;
    }
    #como-funciona .swiper-slide {
        padding: 32px 7% 90px;
    }
    #como-funciona ul {
        padding-left: 6%;
    }
    #passoapasso {
        padding: 40px 5px;

    }
    #passoapasso .left {
        left: 4%;
    }
    #passoapasso .text-box {
        margin-top: 5%;
        width: 48%;
    }
    #passoapasso .text-box p.short {
        display: block;
    }
    #passoapasso .text-box p.long {
        display: none;
    }
    #passoapasso #contrato {
        margin-top: 48%;
    }
    #passoapasso #implantacao {
        margin-top: 88%;
        left: 42%;
    }
    #passoapasso #cadastro-de-acesso {
        margin-top: 131%;
    }
    #passoapasso h3 {
        font-size: 4.5vw;
    }
    #passoapasso p {
        font-size: 2.7vw;
    }
    #passoapasso .description p {
        font-size: 1rem;
    }
}
@media screen and (max-width: 1023px) {
    #como-funciona .img-overlay {
        width: 53%;
        float: none;
        margin: 0 auto 41px;
    }
    #como-funciona .img-overlay img {
        width: 100%;
        float: none;
    }
}

@media screen and (min-width: 1400px) {
    #passoapasso h3 {
        font-size: 32px;
    }
    #passoapasso p {
        font-size: 18px;
    }
}
@media only screen and (max-width: 840px) {
    .graph {
        display: none
    }
    .hero-section {
        border-bottom-left-radius: 8vw;
        border-bottom-right-radius: 8vw;
    }
    #hero-section h2 {
        font-size: 5vw;
        line-height: 6vw;
        text-align: right;
        float: right;
        margin-top: -7vw;
    }
    .A1 {
        width: 95%;
        height: 35vw;
    }
    .B1 {
        width: auto;
        height: auto;
        margin: 3vw 0;
        font-size: 5vw;
    }
    .wa-button {
        float: left;
        margin-top: 2vw;
        width: 48%;
        height: 26%;
        font-size: 3.2vw;
    }
    .wa-button img {
        height: 53%;
    }

    /*----------- footer section ---------*/
    .column-1 #logo {
        width: 130px;
        height: 54px;
    }

    .footer-section .column-heading {
        font-size: 13px;
    }

    .footer-section li {
        font-size: 11px;
        margin-top: 14px;
    }

    .column-3 .icon-text {
        margin-top: 14px;
    }


    .column-1 .social-icons i {
        font-size: 22px;
    }

}
